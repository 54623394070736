<template>
  <div class="home">
    <iframe
      ref="iframe"
      :src="src"
      frameborder="no"
      scrolling="auto"
      width="100%"
      height="100%"
    >
    </iframe>
    <div id="pz-widget-container">
      <div class="pz-drag-widget-container">
        <div class="pz-drag-widget-container__btn">
          <div class="inner-transform-wrapper" @click="show = true">切换<br />线路</div>
        </div>
      </div>
    </div>
    <van-popup
  v-model="show"
  closeable
  position="bottom"
  :style="{ height: '40%' }"
  round>
  <div class="item-box">
    <div class="title">线路选择</div>
    <div class="item-dis">
      <div class="item" v-for="(item,index) in urlall.linelist" :key="index" @click="change(item,index)" :class="{active: classindex == index}">
    {{item.name}}
  </div>
    </div>
  <div class="title">联系客服</div>
  <div style="display:flex;">
    <div class="item" @click="toRescue()">在线客服</div>
  </div>
  </div>
  </van-popup>
  <!-- <div v-html="kfurl"></div> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
import axios from 'axios'
Vue.use(Toast);
export default {
  data() {
    return {
      src: "",
      // height:''
      show:false,
      classindex:0,
      urlall:{}
    };
  },
  created() {
    // this.src = this.list[0].src
  },
  mounted() {
    if (this._isMobile()) {
            axios.get('../../static/sample.json')
      .then(response => {
        console.log(response)
        this.urlall = response.data
        this.src = this.urlall.linelist[0].websrc
      })
      .catch(error => {
        console.log(error);
      });
    }else{
       axios.get('../../static/sample.json')
      .then(response => {
        this.urlall = response.data
        this.src = this.urlall.linelist[0].pcsrc
      })
      .catch(error => {
        console.log(error);
      });
    } 
  },
  methods: {
     _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    change(item,index){
      Toast('切换线路中，请稍等...');
      if(this._isMobile()){
          this.src = item.websrc
      }else{
        this.src = item.pcsrc
      }
      this.classindex = index
      this.show = false
    },
     toRescue() {
       Toast('客服接入中，请稍等...');
        this.src = this.urlall.kfUrl;
        this.show = false
        // this.showkf = true
      },
  },
};
</script>
<style lang="less">
.home {
  height: 100%;
}
#pz-widget-container {
  // width: 0;
  // height: 0;
  position: fixed;
bottom: 130px;
    right: 0;
  z-index: 10;
  font-size: 26px;
  font-weight: 500;
}
.pz-drag-widget-container {
  width: 96px;
  height: 96px;
  cursor: pointer;
  // position: absolute;
  // top: 1400px;
  // left: 654px;
}
.pz-drag-widget-container__btn {
    width: 96px;
    height: 96px;
    background-color: #ff5500 !important;
    color: #fff !important;
    border-radius: 50%;
    box-shadow: 0 6px 10px -2px rgba(0, 0, 0, 0.2), 0 12px 20px 0 rgba(0, 0, 0, 0.14), 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pz-drag-widget-container__btn::before {
    animation: icon_wave_large1 2s 3;
}
.pz-drag-widget-container__btn::after, .pz-drag-widget-container__btn::before {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #ff5500 !important;
    border-radius: 50%;
}
.pz-drag-widget-container__btn .inner-transform-wrapper {
    position: relative;
    z-index: 10;
    transform: rotate(0deg);
    transition: all ease 0.3s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pz-drag-widget-container__btn::after {
    animation: icon_wave_large2 2s 3;
}
@keyframes icon_wave_large1
{
  0% {
    transform: scale(1);
}
100% {
    transform: scale(3);
    opacity: 0;
}
}
@keyframes icon_wave_large2
{
  0% {
    transform: scale(1);
}
100% {
    transform: scale(6);
    opacity: 0;
}
}
.item-box{
  padding: 40px;
}
.title{
  font-size: 30px;
  margin: 30px 0;
  font-weight: 700;
}
.van-popup__close-icon{
  color: #999999 !important;
}
.van-popup__close-icon--top-right{
  width: 36px !important;
  height: 36px !important;
}
.van-popup--bottom.van-popup--round{
  border-radius:16px 16px 0 0;
}
.item-dis{
  display: flex;
}
.item{
  font-size: 34px;
  // border-color: #ff5500;
  border: 2px solid #ff5500;
  color:#ff5500;
  padding: 14px 24px;
  margin: 16px 16px 0 0;
  border-radius: 8px;
  font-weight: 500;
}
.active{
  color: #fff;
  background: #ff5500;
}
</style>
